import { Routes } from '@angular/router';
// guard
import { authGuard } from '@app/auth/guard/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/ai-scanning', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('src/app/auth/auth.module').then((m) => m.AuthModule),
    data: { title: 'Login' },
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('./routes/terms-of-service/terms-of-service.module').then((m) => m.TermsOfServiceModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./routes/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'main',
    loadChildren: () => import('src/app/routes/main/main.module').then((m) => m.MainModule),
    canActivate: [authGuard],
    data: { title: 'Main' },
  },
  // * AI Scanning 페이지
  {
    path: 'ai-scanning',
    loadChildren: () => import('src/app/routes/ai-scanning/ai-scanning.module').then((m) => m.AiScanningModule),
    canActivate: [authGuard],
    data: { title: 'AI Scanning' },
  },
  // * Export to Excel 페이지
  {
    path: 'export-excel',
    loadChildren: () =>
      import('src/app/routes/export-excel/export-excel.module').then((m) => m.ExportExcelModule),
    canActivate: [authGuard],
    data: { title: 'Export to Excel' },
  },

  // ?===============================================================================
  {
    path: 'showcase',
    loadChildren: () => import('src/app/routes/showcase/showcase.module').then((m) => m.ShowcaseModule),
    canActivate: [authGuard],
    data: { title: 'Showcase' },
  },

  { path: '**', redirectTo: 'ai-scanning' },
];
