import { Injectable, ViewContainerRef } from '@angular/core';
// ng-zorro-antd
import { NzModalService } from 'ng-zorro-antd/modal';
// models
import { IModalData } from '@shared/components/alert/alert.model';
// components
import { CustomErrorAlertComponent } from '@shared/components/alert';

@Injectable({ providedIn: 'root' })
export class AppService {
  //#region states
  appViewContainerRef: ViewContainerRef | undefined;
  //#endregion

  constructor(private modal: NzModalService) {}

  //#region methods
  setAppViewContainer(viewContainerRef: ViewContainerRef): void {
    this.appViewContainerRef = viewContainerRef;
  }

  createAlert(modalData: IModalData): void {
    this.modal!.create<CustomErrorAlertComponent, IModalData>({
      nzTitle: '',
      nzContent: CustomErrorAlertComponent,
      nzViewContainerRef: this.appViewContainerRef,
      nzData: modalData,
      nzFooter: null,
    });
  }
  //#endregion
}
